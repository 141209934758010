import React,{Component} from 'react';
import './popup.css';
export default class Popup extends Component{
	render(){
		return(
			<div id='popup'>
				The store is open! <a href="http://blog.bice.rocks" target="blank">Click here</a>
			</div>
		);
	}
}